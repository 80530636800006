import React from "react";

import FormRelatorio from "../../components/Forms/FormRelatorio.jsx";
import withProviderInfo from "../../components/Wrappers/ProviderWrapper";
import auth from  "../../utils/hbackAuth"

const FormRelatorioWithProviderInfo = withProviderInfo(FormRelatorio);

const user = auth.getCurentuser()
let nome_usuario = "";
if (user !== null) {
  nome_usuario = user.nome_usuario;
}

// TODO: Arranjar um jeito melhor de arrumar essas paradas?
const relatorioVazio = {
  "nome": null,
  "descricao": null,
  "responsaveis": [],
  "analises": {},
  "filtrosComplexos": {},
  "filtrosSimples": {
    "periodo": null,
    "valores": {
      "de": null,
      "ate": null,
    },
    "idade": {
      "de": null,
      "ate": null,
    },
    "export": "no_export",
  },
  // TODO: Pegar dos valores já setados em outros lugares como default?
  "empresa": null,
  "seguradora": null,
  "seguir": false,
  "database": null,
  "autor": nome_usuario,
}

class NovoRelatorio extends React.Component {
  render() {
    return (
      <>
        <FormRelatorioWithProviderInfo
          titulo={"Novo Relatório"}
          relatorio={relatorioVazio}
          method="post"
        />
      </>
    );
  }
}

export default NovoRelatorio;
