import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
} from "reactstrap";

import Header from "../../components/Headers/Header.jsx";
import FormCardRelatorio from "./FormCardRelatorio.jsx";
import CardFiltrosSimples from "./CardFiltrosSimples.jsx";
import CardFiltrosComplexos from "./CardFiltrosComplexos.jsx";
import FormAnalises from "./FormAnalises.jsx";
import hback from "../../utils/httpService";
import auth from "../../utils/hbackAuth";

class FormRelatorio extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            analisesAtivas: null,
            // TODO: Quebrar o que a gente salva do relatório em mais states?
            relatorio: this.props.relatorio,
            filtrosComplexos: this.props.relatorio.filtrosComplexos,
            erros: {},
            seguir: this.props.relatorio.seguir,
        }

        this.save = this.save.bind(this);
        this.checaCamposObrigatorios = this.checaCamposObrigatorios.bind(this);
    }

    componentDidMount(){
        hback.get("listaAnalises")
        .then((response) => {
            this.setState((state, _) => {
                let newState = state;
                const responseData = response.data;
                let analisesAtivas = responseData;
                newState.analisesAtivas = analisesAtivas;

                return newState;
            })
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 403 || error.response.status === 401) {
                auth.logout()
            }
        })
    }

    checaCamposObrigatorios(){
        let relatorio = this.state.relatorio;
        let campos_vazios = [];
        ['nome', 'empresa', 'seguradora'].forEach((campo) => {
            if(!relatorio[campo] || relatorio[campo] === ""){
                campos_vazios.push(campo);
            }
        })

        return campos_vazios;
    }

    save(){
        let relatorio = this.state.relatorio;
        // TODO: Checagens todas aqui
        relatorio.empresa = this.props.empresa;
        relatorio.database = this.props.db_empresa;
        relatorio.seguradora = this.props.seguradora;
        relatorio.subestipulante = this.props.subestipulante;
        relatorio.seguir = this.state.seguir;
        relatorio.filtrosComplexos = {};

        let erros = {};
        let campos_vazios = this.checaCamposObrigatorios();

        if (campos_vazios.length > 0){
            campos_vazios.forEach((campo) => {
                erros[campo] = "empty";
            })

            this.setState({erros: erros})
            return
        }

        let transfer_data = new FormData();

        for (let [nome, filtro_info] of Object.entries(this.state.filtrosComplexos)){
            transfer_data.append(nome, filtro_info.arquivo);
            // TODO: Arranjar um jeito de tirar isso aqui com segurança?
            relatorio.filtrosComplexos[nome] = filtro_info;
        }

        //gambs
        // TODO: Estudar uma solução que não seja só tacar tudo num json?
        let json_relatorio = JSON.stringify(relatorio);
        transfer_data.append("json_relatorio", json_relatorio);

        let request_params = {
            headers: {
                "content-type": `multipart/form-data; boundary=${transfer_data._boundary}`
            }
        }

        hback[this.props.method]("/relatorio", transfer_data, request_params)
        .then(() => {
            window.location = "/relatorios";
        })
        .catch((error) => {
            this.setState({erros: error.response.data})
            // TODO: Tratar erro (Colocar algum aviso na tela?)
        });
    }

    saveFiltroComplexo = (nomeNovo, nomeAntigo, filtroUpdate) => {
        let filtrosComplexos = this.state.filtrosComplexos;
        const filtroInfo = filtrosComplexos[nomeAntigo] || {};

        Object.entries(filtroUpdate).forEach(([filtroKey, filtroValue]) => {
            if(filtroKey !== "arquivo" || filtroValue){
                filtroInfo[filtroKey] = filtroValue;
            }
        });

        if (nomeAntigo !== '' && nomeNovo !== nomeAntigo){
            Object.values(filtrosComplexos).forEach((filtro) => {
                if(filtro.origem === nomeAntigo){
                    filtro.origem = nomeNovo;
                }
            });
            delete filtrosComplexos[nomeAntigo];
        }

        filtrosComplexos[nomeNovo] = filtroInfo;

        this.setState({
            filtrosComplexos: filtrosComplexos
        })
    }

    deleteFiltroComplexo(nomeAntigo){
        if(nomeAntigo !== ""){
            let filtrosComplexos = this.state.filtrosComplexos;
            const filtroAntigo = filtrosComplexos[nomeAntigo];
            delete filtrosComplexos[nomeAntigo];
            Object.values(filtrosComplexos).forEach((filtroComplexo) => {
                if(filtroComplexo.origem === nomeAntigo){
                    filtroComplexo.origem = filtroAntigo.origem;
                };
            });
            this.setState({
                filtrosComplexos: filtrosComplexos
            })
        };
    }

    updateFiltroSimples(info_filtros_simples) {
        let relatorio = this.state.relatorio;
        relatorio.filtrosSimples = info_filtros_simples;
        this.setState({
            relatorio: relatorio
        });
    }

    updateAnalises = (value) => {
        this.setState((_) => {
            return value
        })
    }

    updateExportarBrutos = (value) => {
        this.setState((state) => {
            let newState = state;
            newState.relatorio.exportar_brutos_analises = value;
            return newState;
        })
    }

    render(){
        // Deve ter uma forma mais razoável de gerenciar esse treco de estado todo...
        // TODO: Ver uma forma de refatorar todas essas paradas de setState?
        let relatorio = this.state.relatorio
        let componentAnalises;
        if (this.state.analisesAtivas){
            componentAnalises = <>
                <Card className="bg-secondary shadow">
                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div className="d-flex justify-content-between">
                            <h2 className="mb-0">Análises</h2>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                        <FormAnalises
                            idForm="relatorio-analises"
                            categorias={this.state.analisesAtivas}
                            analisesSelecionadas={relatorio.analises}
                            atualizaAnalises={this.updateAnalises}
                            exportarBrutos={relatorio.exportar_brutos_analises}
                            atualizaExportarBrutos={this.updateExportarBrutos}
                            seguradora={this.props.seguradora}
                        />
                    </CardBody>
                </Card>
            </>
        }
        else{
            componentAnalises = <Spinner />
        }

        let nomeDescUpdateFunctions = {};
        ["nome", "descricao"].forEach((field) => {
            nomeDescUpdateFunctions[field] = (value) => {
                this.setState((state, props) => {
                    let newState = state;
                    newState.relatorio[field] = value;
                    delete newState.erros[field];
                    return newState;
                })
            }
        });

        //gambs
        // TODO: Arranjar um jeito de mostrar um erro quando tem selecionado
        // se for o caso
        let erros = this.state.erros;

        ["empresa", "seguradora"].forEach((campo) => {
            if (this.props[campo]) {
                delete erros[campo];
            }
        });

        let autor = relatorio.autor;
        // Caso o autor não esteja definido - Imagino que só ocorra nos relatórios de teste
        // pré-lançamento
        if (!autor){
            autor = relatorio.responsaveis[0];
        }

        return (
            <>
                <Header
                    empresa={this.props.empresa}
                    listaEmpresas={this.props.listaEmpresas}
                    updateEmpresa={this.props.onChangeEmpresa}
                    seguradora={this.props.seguradora}
                    listaSeguradoras={this.props.listaSeguradoras}
                    updateSeguradora={this.props.onChangeSeguradora}
                    subestipulante={this.props.subestipulante}
                    listaSubestipulantes={this.props.listaSubestipulantes}
                    updateSubestipulante={this.props.onChangeSubestipulante}
                    autor={this.props.autor}
                    listaAutor={this.props.listaAutor}
                    loading={this.props.loading}
                    erros={erros}
                />
                <Container className="mt--4" fluid>
                    <Row>
                        <Col xl="5">
                            <div>
                                <FormCardRelatorio
                                    titulo={this.props.titulo}
                                    nome={relatorio.nome}
                                    descricao={relatorio.descricao}
                                    autor={autor}
                                    updateFunctions={nomeDescUpdateFunctions}
                                    onSave={this.save}
                                    erros={this.state.erros}
                                    seguir={this.state.seguir}
                                    onChangeSeguir={(value)=>{
                                        this.setState({seguir:value});
                                    }}
                                    nosave={!this.props.method}
                                />
                            </div>
                        </Col>

                        <Col xl="7">
                            <Row>
                                <Col className="mb-5 mb-xl-0" xl="6">
                                    <CardFiltrosSimples
                                        filtros={relatorio.filtrosSimples}
                                        updateFunction={(info_filtros_simples) => {this.updateFiltroSimples(info_filtros_simples)}}
                                    />
                                </Col>
                                <Col className="mb-5 mb-xl-0" xl="6">
                                    {componentAnalises}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-5 mb-xl-0">
                                    <CardFiltrosComplexos
                                        analises={this.state.analisesAtivas}
                                        id_empresa={this.props.id_empresa}
                                        seguradora={this.props.seguradora}
                                        filtros={this.state.filtrosComplexos}
                                        onSave={this.saveFiltroComplexo}
                                        onDelete={(nomeAntigo) => this.deleteFiltroComplexo(nomeAntigo)}
                                        erros={this.state.erros.filtro_complexo || {}}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default FormRelatorio;