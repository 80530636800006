import React from "react";

//styled-components
import { StyledSearchInput } from './styles';


const SearchInput = (props) => {
    return (
        <StyledSearchInput 
            placeholder={props.placeholder}
            onChange={(e) => {
                props.onChange(e);
            }}
            hidden={props.hidden}
        />
    )
}

export default SearchInput;