import styled from 'styled-components';

import {
    UncontrolledDropdown,
} from "reactstrap";

export const UncontrolledDropdownS = styled(UncontrolledDropdown)`
    position: relative;
`;

// export const Wrapper = styled.div`
//     display: flex;
//     position: relative;
//     /* transform: translateY(-16px); */
// `;

export const Div = styled.div`
    position: relative;
    z-index: 100;
    box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
    background-color: white;
`;