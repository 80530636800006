
import React from "react";
import { useState } from "react";
import { PropTypes } from "prop-types";


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";


function buildListaItems(items, callback){
  return Object.entries(items).map(([value, optionName]) => {
    return <DropdownItem
      onClick={() => {callback(value)}}
      key={value}
    >
      {optionName}
    </DropdownItem>;
  });
}

const StyledDropdown = ({ selectedOptionName, items, onSelect }) => {
  const [isOpen, setOpen] = useState(false);

  return(<Dropdown isOpen={isOpen} toggle={() => {setOpen(!isOpen);}}>
    <DropdownToggle caret color="default" size="sm" data-toggle="dropdown">
      {selectedOptionName}
    </DropdownToggle>
    <DropdownMenu>
      {buildListaItems(items, onSelect)}
    </DropdownMenu>
  </Dropdown>)
};

StyledDropdown.propTypes = {
  selectedOptionName: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default StyledDropdown;
