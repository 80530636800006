import React from "react";
import { Card, CardHeader, Spinner, Container, Row, Col } from "reactstrap";
import Fuse from "fuse.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import hback from "../../utils/httpService";
import Header from "../../components/Headers/Header.jsx";
import PaginatedTable from "../../components/Tables/PaginatedTable.jsx";
import withProviderInfo from "../../components/Wrappers/ProviderWrapper.jsx";
import SearchInput from "../../components/SearchInput/SearchInput.jsx";

/*
// TODO: limitar quantos botões de paginação mostra em baixo?
let botoes_vizinhos_visiveis = 5
*/

class Sincronizacao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      button_on: false,
      busca: "",
    };

    this.set_busca = this.set_busca.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let toggle_button = !this.state.button_on;
    this.setState({ button_on: toggle_button, busca: "" });
  }

  set_busca(e) {
    this.setState({ busca: e.target.value });
  }

  render() {
    const fuseOptions = {
      keys: ["data", "nome_empresa", "seguradoras"],
    };

    let listaSincBase = this.props.listaSincEmpresas;
    // TODO: Arrumar se algum dia o id_empresa vier com id de verdade ao inves de nome
    if (this.props.id_empresa) {
      listaSincBase = listaSincBase.filter(
        (item) => item.nome_empresa === this.props.id_empresa
      );
    }

    if (this.props.seguradora) {
      listaSincBase = listaSincBase.filter(
        (item) => item.seguradoras.indexOf(this.props.seguradora) !== -1
      );
    }

    let fuse = new Fuse(listaSincBase, fuseOptions);
    if (this.state.busca.length > 0) {
      listaSincBase = fuse.search(this.state.busca).map((item) => item.item);
    }

    let dropdownBusca;
    if (this.state.button_on) {
      dropdownBusca = (
        <Col className="col-3">
          <SearchInput
            placeholder="Buscar..."
            value={this.state.busca}
            onChange={this.set_busca}
          />
          <FontAwesomeIcon
            className="ml-2"
            icon={faTimesCircle}
            color="#2f2d43"
            onClick={() => this.toggle()}
          />
        </Col>
      );
    } else {
      dropdownBusca = (
        <Col className="col-1">
          <FontAwesomeIcon
            icon={faSearch}
            color="#2f2d43"
            onClick={() => this.toggle()}
          />
        </Col>
      );
    }

    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Ultimas Sincronizações</h3>
                  </Col>

                  {dropdownBusca}
                </Row>
              </CardHeader>
              <PaginatedTable
                items={listaSincBase.map((sincInfo) => {
                  const nomeEmpresa = sincInfo.nome_empresa;
                  const abrevEmpresa =
                    nomeEmpresa.length > 40
                      ? nomeEmpresa.slice(0, 30) +
                        "..." +
                        nomeEmpresa.slice(nomeEmpresa.length - 5)
                      : nomeEmpresa;

                  let listSeguradoras = sincInfo.seguradoras.split(",");
                  let count = 0;

                  let elementosSeguradoras = [];
                  let rest = listSeguradoras;

                  while (rest.length > 0) {
                    let head = rest.slice(0, 3);
                    rest = rest.slice(3);
                    elementosSeguradoras.push(
                      <div key={"seguradoras" + nomeEmpresa + count}>
                        {head.join(", ")}
                        <br />
                      </div>
                    );
                    count += 1;
                  }

                  return {
                    nomeEmpresa: {
                      rawValue: nomeEmpresa,
                      displayItem: abrevEmpresa,
                    },
                    dataSincronizacao: {
                      rawValue: sincInfo.timestamp,
                    },
                    seguradoras: {
                      rawValue: sincInfo.seguradoras,
                      displayItem: elementosSeguradoras,
                    },
                  };
                })}
                headers={[
                  {
                    key: "nomeEmpresa",
                    label: "Empresa",
                    sortable: true,
                  },
                  {
                    key: "seguradoras",
                    label: "Seguradoras",
                    sortable: true,
                  },
                  {
                    key: "dataSincronizacao",
                    label: "Data de Sincronização",
                    sortable: true,
                  },
                ]}
                defaultSortKey="nomeEmpresa"
                // TODO: Mover busca pra componente de tabela e remover isso
                skipSort={this.state.busca !== ""}
              />
            </Card>
          </div>
        </Row>
      </>
    );
  }
}

class SincronizacoesAsyncWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      sincEmpresas: [],
    };
    // this.empresa = this.props.empresa;
    this.id_empresa = this.props.id_empresa;
    this.seguradora = this.props.seguradora;

    this.updateLista = this.updateLista.bind(this);
    this.updateEmpresa = this.updateEmpresa.bind(this);
    this.updateSeguradora = this.updateSeguradora.bind(this);
    this.updateSubestipulante = this.updateSubestipulante.bind(this);
  }

  componentDidMount() {
    this.updateLista();
  }

  updateLista() {
    /* TODO: Configurar endereços adequadamente */
    hback
      .get("datasSincronizacao", {
        params: {
          empresa: this.id_empresa,
          seguradora: this.seguradora,
        },
      })
      .then((response) => {
        this.setState({ loaded: true, sincEmpresas: response.data });
      })
      .catch((ex) => {
        console.log("sincronizacao: ", ex);
        this.setState({
          loaded: true,
          sincEmpresas: [],
        });
      });
  }

  updateEmpresa(empresa_info) {
    this.id_empresa = empresa_info.id_empresa;
    this.updateLista();
    this.props.onChangeEmpresa(empresa_info);
  }

  updateSeguradora(seguradora_info) {
    this.seguradora = seguradora_info.seguradora;
    this.updateLista();
    this.props.onChangeSeguradora(seguradora_info);
  }

  updateSubestipulante(...args) {
    this.props.onChangeSubestipulante(...args);
  }

  render() {
    let componentToRender;
    if (this.state.loaded) {
      componentToRender = (
        <Sincronizacao
          listaSincEmpresas={this.state.sincEmpresas}
          id_empresa={this.props.empresa}
          seguradora={this.props.seguradora}
        />
      );
    } else {
      componentToRender = <Spinner />;
    }

    return (
      <>
        <Header
          empresa={this.props.empresa}
          listaEmpresas={this.props.listaEmpresas}
          updateEmpresa={this.updateEmpresa}
          seguradora={this.props.seguradora}
          listaSeguradoras={this.props.listaSeguradoras}
          updateSeguradora={this.updateSeguradora}
          subestipulante={this.props.subestipulante}
          listaSubestipulantes={this.props.listaSubestipulantes}
          updateSubestipulante={this.updateSubestipulante}
          loading={this.props.loading}
        />
        <Container className="mt--7" fluid>
          {componentToRender}
        </Container>
      </>
    );
  }
}

export default withProviderInfo(SincronizacoesAsyncWrapper);
