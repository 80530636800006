import React from "react";
import { useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";


const buildHeaderCells = (headers, onSelectSort, reverseSortKeys, setReverseSortKeys) => {
    return headers.map((header) => {
        return (
            <th scope="col" key={header.key}>
                {header.label}
                {
                    header.sortable && (
                    <Button
                        className="btn-icon-only text-default"
                        href="#"
                        role="button"
                        color=""
                        onClick={() => {
                            reverseSortKeys[header.key] = !reverseSortKeys[header.key];
                            setReverseSortKeys(reverseSortKeys);
                            onSelectSort(header.key, reverseSortKeys[header.key]);
                        }}
                    >
                        <i className={reverseSortKeys[header.key] ? "ni ni-bold-down" : "ni ni-bold-up"} />
                    </Button>)
                }
            </th>
        );
    })
}

const PaginatedTableHeader = ({ headers, onSelectSort }) => {
    const [reverseSortKeys, setReverseSortKeys] = useState(headers.filter((header) => header.sortable)
        .reduce((keysDict, header) => {keysDict[header.key] = false; return keysDict}, {}));

    const headerCells = buildHeaderCells(headers, onSelectSort, reverseSortKeys, setReverseSortKeys);

    return(
        <thead className="thead-light">
            <tr>
                {headerCells}
            </tr>
        </thead>
    )
};

PaginatedTableHeader.propTypes = {
    headers: PropTypes.array.isRequired,
    onSelectSort: PropTypes.func,
};

export default PaginatedTableHeader;
