import { useEffect } from "react";
import auth from "../../utils/hbackAuth";

function Logout() {
  useEffect(() => {
    auth.logout();
    window.location = "/login";
  }, []);
  return null;
}

export default Logout;
