import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
} from "reactstrap";

import FiltroSimples from "../Filters/FiltroSimples";


class FiltrosSimples extends React.Component {
    render() {
        return (
            <>

                <Card className="bg-secondary shadow">
                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div className="d-flex justify-content-between">
                            <h2 className="mb-0">Filtros Simples</h2>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <FiltroSimples
                                filtros={this.props.filtros}
                                updateFunction={this.props.updateFunction}
                            />
                        </Form>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default FiltrosSimples;

