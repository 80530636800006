import React from "react";
import { Navbar, Nav, Container, Media } from "reactstrap";

const HealthbotNavbar = ({ pageTitle, userName }) => {
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {pageTitle}
          </div>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <i className="ni ni-circle-08"></i>
              </span>
              <Media className="ml-2 d-none d-lg-block">
                <span className="mb-0 text-sm font-weight-bold text-white">
                  {userName}
                </span>
              </Media>
            </Media>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default HealthbotNavbar;
