import React from "react";
import { useState, useEffect, useRef } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import Sidebar from "./components/Sidebar/Sidebar.jsx";
import Login from "./views/pages/Login/Login.jsx";
import Logout from "./components/Actions/Logout";
import Relatorios from "./views/pages/Relatorios.jsx";
import Sincronizacao from "./views/pages/Sincronizacao.jsx";
import DataBrutos from "views/pages/DataBrutos.jsx";
import NovoRelatorio from "./views/pages/NovoRelatorio.jsx";
import EditarRelatorio from "./views/pages/EditarRelatorio.jsx";
import VisualizarRelatorio from "./views/pages/VisualizarRelatorio.jsx";
import CopyrightFooter from "./components/Footers/CopyrightFooter.jsx";
import HealthbotNavbar from "./components/Navbars/HealthbotNavbar.jsx";
import logo from "./assets/img/brand/healthbit-logo.png";
import auth from "./utils/hbackAuth";
import ProtectedRoute from "./components/Actions/ProtectedRoute";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/style.scss";

export default function App() {
  let location = useLocation();
  const [userName, setUserName] = useState("");
  const content = useRef("mainContent");

  useEffect(() => {
    const user = auth.getCurentuser();
    if (user !== null) setUserName(user.nome_usuario);
  }, []);

  function handlePageTitle() {
    const { pathname } = location;

    if (pathname === "/relatorios") {
      return "Lista de Relatórios";
    }
    if (pathname === "/sincronizacao") {
      return "Lista de Sincronizações";
    }
    if (pathname === "/novorelatorios") {
      return "Novo Relatório";
    }
    if (pathname === "/databrutos") {
      return "Lista de cargas de dados brutos";
    }
  }
  return (
    <>
      {location.pathname !== "/login" && (
        <Sidebar
          logo={{
            innerLink: "/",
            imgSrc: logo,
            imgAlt: "...",
          }}
        />
      )}
      <div className="main-content" ref={content}>
        {location.pathname !== "/login" && (
          <HealthbotNavbar pageTitle={handlePageTitle()} userName={userName} />
        )}
        <Switch>
          <ProtectedRoute path="/relatorios" component={Relatorios} />
          <ProtectedRoute path="/sincronizacao" component={Sincronizacao} />
          <ProtectedRoute path="/databrutos" component={DataBrutos} />
          <ProtectedRoute path="/novorelatorio" component={NovoRelatorio} />
          <ProtectedRoute
            path="/visualizarrelatorio/:id"
            component={VisualizarRelatorio}
          />
          <ProtectedRoute
            path="/editarrelatorio/:id"
            component={EditarRelatorio}
          />
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/logout" component={Logout} />
          <Redirect from="/" to="/relatorios" />
        </Switch>
        <CopyrightFooter />
      </div>
    </>
  );
}
