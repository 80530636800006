import React, { useState } from 'react';

import {
    Col,
    FormGroup,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
  } from "reactstrap";

import Checkbox from '../Checkbox';

const CheckboxMultipleChoices = ({selectedOptions, items, onSelect, label, ...props}) => {

    const [isOpen, setOpen] = useState(false);

    function buildListaItems(items, callback){
        return Object.entries(items).map(([value, optionName]) => {
            return <Checkbox
            type="checkbox"
            onChange={(checked) => {callback(value, checked)}}
            key={value}
            id={value}
            label={optionName}
            checked={selectedOptions[value]}
          />;
        });
      }

    return (
        <div className="custom-control custom-control-alternative">
            <Row>
                <Col>
                    <FormGroup>
                        <Row>
                            <span className="text-muted">{label}</span>
                        </Row>
                        <Row>
                            <Dropdown isOpen={isOpen} toggle={() => {setOpen(!isOpen);}}>
                                <DropdownToggle caret color="default" size="sm" data-toggle="dropdown">
                                    Escolher
                                </DropdownToggle>
                                <DropdownMenu>
                                    {buildListaItems(items, onSelect)}
                                </DropdownMenu>
                            </Dropdown>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
};

export default CheckboxMultipleChoices;