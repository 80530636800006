import React from "react";

import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
} from "reactstrap";

import ModalFiltroComplexo from "../../components/Filters/Complexos/ModalFiltroComplexo.jsx";


const mensagensErro = {
    nome_invalido: "Nome de filtro inválido:",
    conteudo_invalido: "Arquivo de filtro inválido no seguinte filtro:"
}

class CardFiltrosComplexos extends React.Component {
    constructor(props){
        super(props);
        let state = {
            modalFiltroComplexo: false
        };

        Object.keys(props.filtros).forEach((nome_filtro) => {
            state[nome_filtro] = false;
        });

        this.state = state;
    };

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    checkAndSave = (nome_novo, nome_antigo, filtro_info) => {
        let erro = false;

        //TODO: Ver se tem como extrair isso de forma mais generica?
        let erro_key = "erro-arquivo-" + nome_antigo;
        if(filtro_info.nome_arquivo == null){
            this.setState({
                [erro_key]: true
            })
            erro = true;
        }
        else{
            this.setState({
                [erro_key]: false
            })
        }

        erro_key = "erro-nome-vazio-" + nome_antigo;
        if(nome_novo === ""){
            this.setState({
                [erro_key]: true
            })
            erro = true;
        }
        else{
            this.setState({
                [erro_key]: false
            })

            erro_key = "erro-nome-em-uso-" + nome_antigo;
            if((nome_novo !== nome_antigo) && (Object.keys(this.props.filtros).findIndex((elem) => {return elem === nome_novo}) !== -1)){
                this.setState({
                    [erro_key]: true
                })
                erro = true;
            }
            else{
                this.setState({
                    [erro_key]: false
                })
            }
        }

        if(!erro){
            this.props.onSave(nome_novo, nome_antigo, filtro_info);
            return true;
        }
        else{
            return false;
        }
    }

    montaModais = () => {
        const filtros = this.props.filtros;
        let antepassados = {};

        Object.entries(filtros).forEach(([nome, filtro]) => {
            antepassados[nome] = [];
            let currentFiltro = filtro
            while(currentFiltro.origem
                && currentFiltro.origem !== "Dados Completos"
                && currentFiltro.origem !== "Filtro Simples"
                && currentFiltro.origem !== "source"
            ){
                antepassados[nome].push(currentFiltro.origem);
                currentFiltro = filtros[currentFiltro.origem];
            };
        });

        let origensPossiveis = {};
        Object.keys(filtros).forEach((nomeFiltro) => {
            origensPossiveis[nomeFiltro] = ["Dados Completos", "Filtro Simples"].concat(
                Object.keys(filtros).filter((outroNome) => {
                    return (nomeFiltro !== outroNome && antepassados[outroNome].indexOf(nomeFiltro) === -1);
                })
            );
        });

        const modaisFiltrosExistentes = Object.entries(filtros).map(([nomeFiltro, filtro]) => {
            return(
                <Modal
                    key={"modal-" + nomeFiltro}
                    isOpen={this.state[nomeFiltro]}
                    toggle={() => this.toggleModal(nomeFiltro)}
                >
                    <ModalFiltroComplexo
                        close={() => {
                            this.setState({
                                ["erro-arquivo-" + nomeFiltro]: false,
                                ["erro-nome-vazio-" + nomeFiltro]: false,
                                ["erro-nome-em-uso-" + nomeFiltro]: false,
                            })
                            this.toggleModal(nomeFiltro)
                        }}
                        filtro={filtro}
                        origensPossiveis={origensPossiveis[nomeFiltro]}
                        onSave={this.checkAndSave}
                        erroArquivo={this.state["erro-arquivo-" + nomeFiltro]}
                        erroNomeVazio={this.state["erro-nome-vazio-" + nomeFiltro]}
                        erroNomeEmUso={this.state["erro-nome-em-uso-" + nomeFiltro]}
                        analises={this.props.analises}
                        id_empresa={this.props.id_empresa}
                        seguradora={this.props.seguradora}
                    />
                </Modal>
            );
        });

        const modalFiltroNovo = [
            <Modal
                key={"modal-novo-filtro-complexo"}
                isOpen={this.state.modalFiltroComplexo}
                toggle={() => this.toggleModal("modalFiltroComplexo")}
            >
                <ModalFiltroComplexo
                    close={() => {
                        this.toggleModal("modalFiltroComplexo");
                        this.setState({
                            ["erro-arquivo-"]: false,
                            ["erro-nome-vazio-"]: false,
                            ["erro-nome-em-uso-"]: false,
                        })

                    }}
                    filtro={{
                        nome: "",
                        nome_arquivo: null,
                    }}
                    origensPossiveis={["Dados Completos", "Filtro Simples"].concat(Object.keys(filtros))}
                    onSave={this.checkAndSave}
                    erroArquivo={this.state["erro-arquivo-"]}
                    erroNomeVazio={this.state["erro-nome-vazio-"]}
                    erroNomeEmUso={this.state["erro-nome-em-uso-"]}
                    analises={this.props.analises}
                    id_empresa={this.props.id_empresa}
                    seguradora={this.props.seguradora}
                />
            </Modal>
        ];
        return modalFiltroNovo.concat(modaisFiltrosExistentes);
    };

    montaBotoes = () => {
        return Object.keys(this.props.filtros).map((nome_filtro) => {
            return (
                <ButtonGroup key={"button-group-" + nome_filtro}>
                    <Button
                        key={"button-" + nome_filtro}
                        onClick={() => this.toggleModal(nome_filtro)}
                    >
                        {nome_filtro}
                    </Button>
                    <Button
                        key={"button-delete-" + nome_filtro}
                        onClick={() => this.props.onDelete(nome_filtro)}
                    >
                        X
                    </Button>
                </ButtonGroup>
            );
        });

    }

    render() {
        let erros = this.props.erros || {};
        let modais = this.montaModais();
        let botoes_filtros = this.montaBotoes();

        const avisoErro = [];
        Object.entries(erros).forEach(([tipo_erro, nome_filtro]) => {
            avisoErro.push(<Row key={tipo_erro}>
                <Alert color="danger">
                    {mensagensErro[tipo_erro] + " " + nome_filtro}
                </Alert>
            </Row>);
        })

        return (
            <>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h2 className="mb-0">Filtros Complexos</h2>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => this.toggleModal("modalFiltroComplexo")}
                                >
                                    Novo Filtro
                                </Button>
                            {modais}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {avisoErro}
                        <Col>
                            <Row className="align-items-center">
                                {/*TODO Quebrar em múltiplas linhas*/}
                                {botoes_filtros}
                            </Row>
                        </Col>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default CardFiltrosComplexos;
