import React from "react";

import { Col } from "reactstrap";
import LabeledDropdown from "../../../components/Dropdown/LabeledDropdown";

const possibleStates = {
  [null]: "Indiferente",
  REDE: "Rede",
  REEMBOLSO: "Reembolso",
};


function Fonte (props){
  return (
    <>
      <Col lg="6">
        <LabeledDropdown
          selectedOptionName={possibleStates[props.value || null]}
          onSelect={(value) => { props.updateFunction(value==='null'? null:value) }}
          items={possibleStates}
          label="Fonte"
        />
      </Col>
    </>
  );
}
export default Fonte;
