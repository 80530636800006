
import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  FormGroup,
  Row
} from "reactstrap";

import StyledDropdown from './StyledDropdown';

const LabeledDropdown = ({label, ...props}) => {
  return (<div className="custom-control custom-control-alternative">
    <Row>
      <Col>
        <FormGroup>
          <Row>
            <span className="text-muted">{label}</span>
          </Row>
          <Row>
            <StyledDropdown
              {...props}
            />
          </Row>
        </FormGroup>
      </Col>
    </Row>
  </div>)
}

LabeledDropdown.propTypes = {
  label: PropTypes.string.isRequired,
}

export default LabeledDropdown;
