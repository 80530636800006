import React from "react";
import PropTypes from "prop-types";

import { useState } from "react";
import {
    Button,
    Collapse,
} from "reactstrap";
import StyledCheckbox from "components/Checkboxes/Checkbox";

import Divider from '@mui/material/Divider';

function CheckboxCategoriaAnalises({ idCheckbox, idCategoria, categoria, analisesSelecionadas, onChange }) {
    const [expande, setExpande] = useState(false);

    const toggle = ()=>{
        setExpande(!expande);
    }

    let todosChecados = true;
    let nenhumChecado = true;

    analisesSelecionadas = analisesSelecionadas || {};

    categoria.analises.forEach((analise) => {
        if(analisesSelecionadas[analise.option_name]){
            nenhumChecado = false;
        }
        else{
            todosChecados = false;
        }
    });

    const categoryCheckbox = <StyledCheckbox
        id={idCheckbox + idCategoria}
        label={categoria.nome}
        checked={todosChecados}
        indeterminate={!todosChecados && !nenhumChecado}
        onChange={()=>{
            let novoValor = true;
            if(todosChecados){
                novoValor = false;
            };
            categoria.analises.forEach((analise) => {
                analisesSelecionadas[analise.option_name] = novoValor;
            });
            onChange(analisesSelecionadas);
        }}
    />

    const checkboxesAnalises = categoria.analises.map((analise) => <div style={{ marginLeft: 20 }} key={analise.option_name}>
        <StyledCheckbox
            id={idCheckbox + idCategoria + analise.option_name}
            label={analise.descricao}
            checked={analisesSelecionadas[analise.option_name]}
            onChange={(value) => {
                analisesSelecionadas[analise.option_name] = value;
                onChange(analisesSelecionadas);
            }}
        />
    </div>);

    return <>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {categoryCheckbox}
            <Button size="sm" onClick={toggle} style={{marginLeft: 3}}> {expande ? "^": "v"} </Button>
        </div>
        <Collapse isOpen={expande}>
            {checkboxesAnalises}
        </Collapse>
    </>
};

CheckboxCategoriaAnalises.propTypes = {
    idCheckbox: PropTypes.string.isRequired,
    idCategoria: PropTypes.string.isRequired,
    categoria: PropTypes.object.isRequired,
    analisesSelecionadas: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

function FormAnalises({
    idForm,
    categorias,
    analisesSelecionadas,
    atualizaAnalises,
    exportarBrutos,
    atualizaExportarBrutos,
    seguradora,
}){
    // Retrocompatibilidade
    // TODO: Arrumar isso do outro lado
    // analisesSelecionadas = Object.entries(analisesSelecionadas).reduce(
    //     (prevAnalises, [categoria, valor]) => {
    //         if(typeof(valor) === "boolean" || valor === null || valor === undefined){
    //             prevAnalises[categoria] = categorias[categoria].analisesSelecionadas.reduce(
    //                 (prev, analise)=> {
    //                     prev[analise.option_name] = valor;
    //                     return prev;
    //                 },
    //                 {}
    //             );
    //             return prevAnalises;
    //         }
    //         else{
    //             prevAnalises[categoria] = valor;
    //             return prevAnalises;
    //         }
    //     },
    //     {}
    // );

    const checkboxCategorias = Object.entries(categorias).map(([idCategoria, categoria]) => {
        const idCheckbox = idForm + idCategoria
        if(!categoria.seguradoras || categoria.seguradoras.indexOf(seguradora) !== -1) {
            return <CheckboxCategoriaAnalises
                key={idCheckbox}
                idCheckbox={idCheckbox}
                idCategoria={idCategoria}
                categoria={categoria}
                analisesSelecionadas={analisesSelecionadas}
                onChange={(idAnalise, value) => {
                    atualizaAnalises(idAnalise, value)
                }}
            />
        }
    });
    return <>
        <StyledCheckbox
            id={idForm + "-exportar-brutos-analises"}
            label="Exportar dados brutos para essas análises"
            checked={exportarBrutos}
            onChange={atualizaExportarBrutos}
        />
        <Divider />
        {checkboxCategorias}
    </>
}

FormAnalises.propTypes = {
    idForm: PropTypes.string.isRequired,
    categorias: PropTypes.object.isRequired,
    analisesSelecionadas: PropTypes.object.isRequired,
    atualizaAnalises: PropTypes.func.isRequired,
    exportarBrutos: PropTypes.bool.isRequired,
    atualizaExportarBrutos: PropTypes.func.isRequired,
    seguradora: PropTypes.string,
}

export default FormAnalises;
