import React from "react";
import { useState, useEffect } from "react";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import fileDownload from "js-file-download";
import CsvDownload from 'react-json-to-csv';

import FormAnalises from "../FormAnalises";

import LabeledDropdown from "../../../components/Dropdown/LabeledDropdown";
import hback from "../../../utils/httpService";
import StyledCheckbox from "components/Checkboxes/Checkbox";

const exportTypes = {
  resumo_filtro: "Resumo dos dados",
  resumo_todos: "Resumo dos dados com histórico completo",
  "": "Não exportar",
}

const FormFiltroComplexo = ({close,
  nome,
  nome_arquivo,
  origem,
  origensPossiveis,
  update_nome,
  update_arquivo,
  update_origem,
  erroArquivo,
  erroNomeVazio,
  erroNomeEmUso,
  exportType,
  onChangeExportType,
  onUpdateAnalises,
  analisesSelecionadas,
  analises,
  nomeAntigo,
  exportarBrutos,
  updateExportarBrutos,
  exportarBrutosAnalises,
  updateExportarBrutosAnalises,
  id_empresa,
  seguradora,
  conteudo,
}) => {
  const [analisesToggle, setAnalisesToggle] = useState(false);
  const [sampleJson, setSampleJson] = useState(null);

  const fileInput = React.createRef();

  let downloadSampleComponent;
  let ultimoFiltroDownloadComponent;
  useEffect(() => {
    if(!sampleJson && id_empresa && seguradora){
      hback.get("/getSample/" + id_empresa + "/" + seguradora)
      .then((response) => {
        setSampleJson(response.data);
      })
    }
  }, [sampleJson, id_empresa, seguradora]);

  if(sampleJson){
    downloadSampleComponent = <CsvDownload data={sampleJson} filename="sample.csv">
      Baixar exemplo de dados
    </CsvDownload>;
  }

  if(conteudo){
    function montaCSVFiltro(){
      const primeiraLinha = conteudo.head.map((item) => {
          if(item.operacao !== 'eq'){
            return item.operacao + '$' + item.campo;
          }
          return item.campo;
        }).join(';') + '\n';
      const restoCSVFiltro = conteudo.content.map((item) => item.join(';')).join('\n');
      return primeiraLinha + restoCSVFiltro;
    };

    function downloadCSVFiltro(){
      fileDownload(montaCSVFiltro(), nome + '.csv');
    };

    ultimoFiltroDownloadComponent = <Row>
      <Button onClick={downloadCSVFiltro}>
        Baixar último filtro salvo
      </Button>
    </Row>
  }

  const handleInput = () => {
    update_arquivo(fileInput.current.files[0]);
  }

  function avisaErroNome(){
    if (erroNomeVazio) {
      return (<Alert color="danger">
        Campo obrigatório
      </Alert>);
    }
    else if (erroNomeEmUso) {
      return (<Alert color="danger">
        Nome já utilizado!
      </Alert>);
    }
  };

  function avisaErroArquivo(){
    if (erroArquivo) {
      return(<Alert color="danger">
        Campo obrigatório
      </Alert>);
    }
  };

  function exibeNomeArquivo(){
    if(nome_arquivo !== null){
      return(<Row>
        Último arquivo: {nome_arquivo}
      </Row>);
    }
  };

  return (
    <>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h2 className="mb-0">
                {nome || "Novo Filtro"}
              </h2>
            </Col>
            <Col className="text-right" xs="4">
              <Button color="primary"
                onClick={close}
                size="sm">
                Voltar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form onSubmit={(e) => {e.preventDefault()}}>
            <div className="pl-lg-4">
              <Row>
                <Col>
                  <FormGroup>
                    {avisaErroNome()}
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Nome
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-nome-filtro"
                      placeholder="Ex: Cardiopatas"
                      type="text"
                      value={nome}
                      onChange={(e) => {update_nome(e.target.value)}}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {exibeNomeArquivo()}
              <Row>
                {avisaErroArquivo()}
                <input
                  type="file"
                  ref={fileInput}
                  onChange={handleInput}
                  />
              </Row>
              <Row>
                {downloadSampleComponent}
              </Row>
              {ultimoFiltroDownloadComponent}
              <Row>
                <LabeledDropdown
                  label="Fonte"
                  selectedOptionName={origem}
                  items={origensPossiveis.reduce(
                    (mapping, innerOrigem) => {
                      mapping[innerOrigem] = innerOrigem;
                      return mapping;
                    }, {})}
                  onSelect={update_origem}
                />
              </Row>
              <Row>
                <LabeledDropdown
                  label="Forma de exportação"
                  selectedOptionName={exportTypes[exportType]}
                  items={exportTypes}
                  onSelect={onChangeExportType}
                />
              </Row>
              <Row>
                <StyledCheckbox
                  label="Exportar Dados Brutos para este filtro"
                  onChange={updateExportarBrutos}
                  id="check-exportar-brutos"
                  checked={exportarBrutos}
                />
              </Row>
              <Row>
                  Análises para este filtro
              </Row>
              <Row>
                <Button onClick={() => {setAnalisesToggle(!analisesToggle)}}>
                  {analisesToggle ? "Esconder" : "Exibir"}
                </Button>
              </Row>
              <Row>
                <Collapse isOpen={analisesToggle}>
                  <FormAnalises
                    idForm={"analises"+nome}
                    categorias={analises}
                    analisesSelecionadas={analisesSelecionadas}
                    atualizaAnalises={onUpdateAnalises}
                    exportarBrutos={exportarBrutosAnalises}
                    atualizaExportarBrutos={updateExportarBrutosAnalises}
                    seguradora={seguradora}
                  />
                </Collapse>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default FormFiltroComplexo;
