import React from "react";

import { Col } from "reactstrap";
import CheckboxMultipleChoices from "../../../components/Checkboxes/CheckboxMultipleChoices";

const possibleStates = {
  clinica: "clinica",
  consulta_ambulatorial: "consulta_ambulatorial",
  consulta_geral: "consulta_geral",
  consulta_pronto_socorro: "consulta_pronto_socorro",
  exame_complexo: "exame_complexo",
  exame_simples: "exame_simples",
  internacao: "internacao",
  outros: "outros",
  tratamento: "tratamento",
};

const CTipo = (props) => {
  const updateCTiposSelecionados = (key, value) => {
    let oldValue = props.value || {};
    if(typeof(oldValue) === "string"){
      oldValue = { [oldValue]: true };
    }
    oldValue[key] = value;
    props.updateFunction(oldValue);
  }

  let selectedValues = props.value || {};
  if(typeof(selectedValues) === "string"){
    selectedValues = { [selectedValues]: true };
  }

  return (
    <>
      <Col lg="6">
        <CheckboxMultipleChoices
          selectedOptions={selectedValues}
          onSelect={updateCTiposSelecionados}
          items={possibleStates}
          label="Tipo"
        />
      </Col>
    </>
  );
}
export default CTipo;
