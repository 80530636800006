import React from "react";
import PropTypes from "prop-types";


const buildRowCells = (rowItem, headers) => {
    return headers.map((header) => {
        const key = Object.keys(rowItem[header.key]).indexOf("displayItem") === -1 ? "rawValue" : "displayItem";
        return(
            <td key={header.key}>
                {rowItem[header.key][key]}
            </td>
        );
    });
}

const buildRows = (items, headers) => {
    return items.map((item, index) => {
        const rowCells = buildRowCells(item, headers);
        return(
            <tr key={index}>
                {rowCells}
            </tr>
        )
    });
}

const PaginatedTableBody = ({ items, headers }) => {
    return (<tbody>
        {buildRows(items, headers)}
    </tbody>);
}

PaginatedTableBody.propTypes = {
    items: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
}

export default PaginatedTableBody
