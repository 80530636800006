import React from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
import Fuse from "fuse.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import hback from "../../utils/httpService";
import Actions from "../../components/Actions/Actions/index.jsx";
import Header from "../../components/Headers/Header.jsx";
import PaginatedTable from "../../components/Tables/PaginatedTable.jsx";
import withProviderInfo from "../../components/Wrappers/ProviderWrapper.jsx";
import SearchInput from "../../components/SearchInput/SearchInput.jsx";
import auth from "../../utils/hbackAuth";

const statusClassDict = {
  error: "bg-danger",
  ok: "bg-success",
  warning: "bg-warning",
};
class DownloadButton extends React.Component {
  render() {
    let downloadLink = this.props.downloadLink;
    if (downloadLink !== undefined) {
      return (
        <Button
          className="btn-icon-only text-default"
          href={downloadLink}
          role="button"
        >
          <i className="ni ni-cloud-download-95" />
        </Button>
      );
    }
    return (
      <Button
        className="btn-icon-only text-default"
        role="button"
        color="secondary"
        disabled
      >
        <i className="ni ni-cloud-download-95" />
      </Button>
    );
  }
}

class Relatorios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      button_on: false,
      busca: "",
      filtraInteressados: false,
    };
  }

  toggle = () => {
    let toggle_button = !this.state.button_on;
    this.setState({ button_on: toggle_button, busca: "" });
  };

  set_busca = (e) => {
    this.setState({ busca: e.target.value });
  };

  buildItemRelatorio = (relatorio) => {
    let itemRelatorio = {
      data: {
        rawValue: relatorio.data,
        displayItem: relatorio.data_formatado,
      },
      estado: {
        rawValue: relatorio.estado_string,
        displayItem: (
          <Badge color="" className="badge-dot">
            <i className={statusClassDict[relatorio.estado_icone]} />
            {relatorio.estado_string}
          </Badge>
        ),
      },
      actions: {
        displayItem: (
          <>
            <DownloadButton
              downloadLink={relatorio.download_url}
              id_relatorio={relatorio.id}
            />
            <Actions
              downloadLink={relatorio.download_url}
              id_elemento={relatorio.id}
              can_edit={relatorio.can_edit}
              can_delete={relatorio.can_delete}
            />
          </>
        ),
      },
    };
    if (this.props.id_empresa === null) {
      itemRelatorio.empresa = {
        rawValue: relatorio.empresa,
      };
    }

    if (this.props.seguradora === null) {
      itemRelatorio.seguradora = {
        rawValue: relatorio.seguradora,
      };
    }

    let targetLink;
    if (relatorio.can_edit) {
      targetLink = "/editarrelatorio/" + relatorio.id;
    } else {
      targetLink = "/visualizarrelatorio/" + relatorio.id;
    }

    const linkRelatorio = (
      <Link to={targetLink}>
        {" "}
        {relatorio.nome} <br /> Autor: {relatorio.autor}{" "}
      </Link>
    );

    itemRelatorio.nome = {
      rawValue: relatorio.nome,
      displayItem: linkRelatorio,
    };

    return itemRelatorio;
  };

  buildItemsListaRelatorio = (relatorios) => {
    return relatorios.map((relatorio) => {
      return this.buildItemRelatorio(relatorio);
    });
  };

  buildTableHeaders = () => {
    let headers = [
      {
        key: "nome",
        label: "Relatórios",
        sortable: true,
      },
    ];

    if (this.props.id_empresa === null) {
      headers.push({
        key: "empresa",
        label: "Empresa",
        sortable: true,
      });
    }

    if (this.props.seguradora === null) {
      headers.push({
        key: "seguradora",
        label: "seguradora",
        sortable: true,
      });
    }

    return headers.concat([
      {
        key: "estado",
        label: "Estado",
        sortable: true,
      },
      {
        key: "data",
        label: "Data",
        sortable: true,
      },
      {
        key: "actions",
        label: "Ações",
        sortable: false,
      },
    ]);
  };

  render() {
    const fuseOptions = {
      keys: [
        "nome",
        "data_formatado",
        "empresa",
        "seguradora",
        "estado_string",
        "autor",
      ],
      threshold: 0.4,
    };

    let relatoriosBase = this.props.listaRelatorios.map((relatorio) => {
      relatorio.data_formatado = new Date(
        Date.parse(relatorio.data + "+00:00")
      ).toLocaleString();
      return relatorio;
    });

    if (this.state.filtraInteressados){
      const userEmail = auth.getCurentuser().email;

      relatoriosBase = relatoriosBase.filter((relatorio) => {
        const interessados = relatorio.interessados
        if (interessados) {
          return interessados.indexOf(userEmail) !== -1;
        }
        return false;
      });
    };

    let fuse = new Fuse(relatoriosBase, fuseOptions);
    if (this.state.busca.length > 0) {
      relatoriosBase = fuse
        .search(this.state.busca)
        .map((itemBusca) => itemBusca.item);
    };

    let dropdownBusca;
    if (this.state.button_on) {
      dropdownBusca = (
        <Col className="col-3">
          <SearchInput
            placeholder="Buscar..."
            value={this.state.busca}
            onChange={this.set_busca}
          />
          <FontAwesomeIcon
            className="ml-2"
            icon={faTimesCircle}
            color="#2f2d43"
            onClick={this.toggle}
          />
        </Col>
      );
    } else {
      dropdownBusca = (
        <Col className="col-1">
          <FontAwesomeIcon
            icon={faSearch}
            color="#2f2d43"
            onClick={this.toggle}
          />
        </Col>
      );
    }

    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Relatórios</h3>
                  </Col>
                  {/* <Col> */}
                    <Button
                      size="sm"
                      onClick={() => {
                        this.setState({
                          filtraInteressados: !this.state.filtraInteressados
                        })
                      }}
                      >
                    {this.state.filtraInteressados ? "Todos Relatórios" : "Relatórios seguidos"}
                    </Button>
                  {/* </Col> */}
                  {dropdownBusca}
                </Row>
              </CardHeader>
              <PaginatedTable
                items={this.buildItemsListaRelatorio(relatoriosBase)}
                headers={this.buildTableHeaders()}
                defaultSortKey="data"
                startRev
                // TODO: Mover busca pra componente de tabela e remover isso
                skipSort={this.state.busca !== ""}
              />
            </Card>
          </div>
        </Row>
      </>
    );
  }
}

class RelatoriosAsyncWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      relatorios: [],
    };
    // this.empresa = this.props.empresa;
    this.id_empresa = this.props.id_empresa;
    this.seguradora = this.props.seguradora;

    this.updateLista = this.updateLista.bind(this);
    this.updateEmpresa = this.updateEmpresa.bind(this);
    this.updateSeguradora = this.updateSeguradora.bind(this);
    this.updateSubestipulante = this.updateSubestipulante.bind(this);
  }
  componentDidMount() {
    this.updateLista();
  }

  updateLista() {
    /* TODO: Configurar endereços adequadamente */
    hback
      .get("listaRelatorios/", {
        params: {
          empresa: this.id_empresa,
          seguradora: this.seguradora,
        },
      })
      .then((response) => {
        this.setState({ loaded: true, relatorios: response.data });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 403 || error.response.status === 401) {
          auth.logout();
        } else {
          this.setState({
            loaded: true,
            relatorios: [],
          });
        }
      });
  }

  updateEmpresa(empresa_info) {
    this.id_empresa = empresa_info.id_empresa;
    this.updateLista();
    this.props.onChangeEmpresa(empresa_info);
  }

  updateSeguradora(seguradora_info) {
    this.seguradora = seguradora_info.seguradora;
    this.updateLista();
    this.props.onChangeSeguradora(seguradora_info);
  }

  updateSubestipulante(...args) {
    this.props.onChangeSubestipulante(...args);
  }

  render() {
    let componentToRender;
    if (this.state.loaded) {
      componentToRender = (
        <Relatorios
          listaRelatorios={this.state.relatorios}
          id_empresa={this.props.empresa}
          seguradora={this.props.seguradora}
        />
      );
    } else {
      componentToRender = <Spinner />;
    }

    return (
      <>
        <Header
          empresa={this.props.empresa}
          listaEmpresas={this.props.listaEmpresas}
          updateEmpresa={this.updateEmpresa}
          seguradora={this.props.seguradora}
          listaSeguradoras={this.props.listaSeguradoras}
          updateSeguradora={this.updateSeguradora}
          subestipulante={this.props.subestipulante}
          listaSubestipulantes={this.props.listaSubestipulantes}
          updateSubestipulante={this.updateSubestipulante}
          loading={this.props.loading}
        />
        <Container className="mt--7" fluid>
          {componentToRender}
        </Container>
      </>
    );
  }
}

export default withProviderInfo(RelatoriosAsyncWrapper);
