import axios from "axios";

const axiosHback = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

axiosHback.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;

  if (!expectedError) {
    console.log("Logging the error", error);
    // alert("Um erro inesperado aconteceu, contate a equipe de TI");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axiosHback.defaults.headers.common["Auth"] = "Bearer: " + jwt;
}

const hback = {
  get: axiosHback.get,
  post: axiosHback.post,
  put: axiosHback.put,
  delete: axiosHback.delete,
  setJwt,
};

export default hback;
