import React from "react";
import Fonte from "./Simples/Fonte.jsx";
import Periodo from "./Simples/Periodo.jsx";
import Valores from "./Simples/Valores.jsx";
import Idade from "./Simples/Idade.jsx";
import CTipo from "./Simples/CTipo.jsx"

import StyledCheckbox from '../Checkboxes/Checkbox';

const FiltroSimples = (props) => {
    const updateFunctions = {}

    const chaves_filtros_simples = ['periodo', 'idade', 'valores', 'ctipo', 'fonte', 'export', 'exportar_brutos'];

    chaves_filtros_simples.forEach((key) => {
      updateFunctions[key] = (valor) => {
        let filtros_simples = props.filtros;
        filtros_simples[key] = valor;
        props.updateFunction(filtros_simples);
      }
    })

    const mudaExport = (exportChecked) => {
      if (exportChecked){
        updateFunctions.export('simples');
      }
      else{
        updateFunctions.export('no_export');
      }
    }

    return (
        <>
          <CTipo
            value={props.filtros.ctipo}
            updateFunction={(valor) => { updateFunctions.ctipo(valor) }}
          />
          <Fonte
            value={props.filtros.fonte}
            updateFunction={(valor) => { updateFunctions.fonte(valor) }}
          />
          <Periodo
            value={props.filtros.periodo}
            updateFunction={(valor) => { updateFunctions.periodo(valor) }}
          />
          <Valores
            value={props.filtros.valores}
            updateFunction={(valor) => { updateFunctions.valores(valor) }}
          />
          <Idade
            value={props.filtros.idade}
            updateFunction={(valor) => { updateFunctions.idade(valor) }}
          />
        <StyledCheckbox
          id="exportarFiltroSimples"
          label="Exportar dados dos filtros simples"
          checked={props.filtros.export === "simples"}
          onChange={mudaExport}
        />
        <StyledCheckbox
          id="exportarBrutosFiltroSimples"
          label="Exportar dados brutos dos filtros simples"
          checked={props.filtros.exportar_brutos}
          onChange={updateFunctions.exportar_brutos}
        />
      </>
    );
}

export default FiltroSimples;