import React from "react";
import { Card, CardHeader, Spinner, Container, Row, Col, Button, Modal } from "reactstrap";
import Fuse from "fuse.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import hback from "../../utils/httpService.js";
import Header from "../../components/Headers/Header.jsx";
import PaginatedTable from "../../components/Tables/PaginatedTable.jsx";
import withProviderInfo from "../../components/Wrappers/ProviderWrapper.jsx";
import SearchInput from "../../components/SearchInput/SearchInput.jsx";
import ModalCargaBrutos from "components/Modals/ModalCompetenciasBrutos.jsx";

/*
// TODO: limitar quantos botões de paginação mostra em baixo?
let botoes_vizinhos_visiveis = 5
*/

class DataBrutos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      button_on: false,
      busca: "",
    };

    this.set_busca = this.set_busca.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle_modal = this.toggle_modal.bind(this);
  }

  toggle() {
    let toggle_button = !this.state.button_on;
    this.setState({ button_on: toggle_button, busca: "" });
  }

  toggle_modal(modal_info) {
      this.setState({
        modal: !this.state.modal,
        modal_info: modal_info
      });
  }

  set_busca(e) {
    this.setState({ busca: e.target.value });
  }

  render() {
    const fuseOptions = {
      keys: ["data", "empresa", "seguradora"],
    };

    let listaBrutoBase = this.props.listaBrutos;
    // TODO: Arrumar se algum dia o id_empresa vier com id de verdade ao inves de nome
    if (this.props.id_empresa) {
      listaBrutoBase = listaBrutoBase.filter(
        (item) => item.empresa === this.props.id_empresa
        );
    }


    if (this.props.seguradora) {
      listaBrutoBase = listaBrutoBase.filter(
        (item) => item.seguradora.indexOf(this.props.seguradora) !== -1
      );
    }

    let fuse = new Fuse(listaBrutoBase, fuseOptions);
    if (this.state.busca.length > 0) {
      listaBrutoBase = fuse.search(this.state.busca).map((item) => item.item);
    }

    let dropdownBusca;
    if (this.state.button_on) {
      dropdownBusca = (
        <Col className="col-3">
          <SearchInput
            placeholder="Buscar..."
            value={this.state.busca}
            onChange={this.set_busca}
          />
          <FontAwesomeIcon
            className="ml-2"
            icon={faTimesCircle}
            color="#2f2d43"
            onClick={() => this.toggle()}
          />
        </Col>
      );
    } else {
      dropdownBusca = (
        <Col className="col-1">
          <FontAwesomeIcon
            icon={faSearch}
            color="#2f2d43"
            onClick={() => this.toggle()}
          />
        </Col>
      );
    }

    let modalItem;
    if (this.state.modal){
      modalItem = <Modal
        isOpen={this.state.modal}
        toggle={() => {this.setState({modal: !this.state.modal})}}
      >
        <ModalCargaBrutos
          infoBrutos={this.state.modal_info}
        />
      </Modal>
    }

    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Ultimas Sincronizações</h3>
                  </Col>

                  {dropdownBusca}
                </Row>
              </CardHeader>
              <PaginatedTable
                items={listaBrutoBase.map((brutoInfo) => {
                  const nomeEmpresa = brutoInfo.empresa;
                  const abrevEmpresa =
                    nomeEmpresa.length > 40
                      ? nomeEmpresa.slice(0, 30) +
                        "..." +
                        nomeEmpresa.slice(nomeEmpresa.length - 5)
                      : nomeEmpresa;

                  const id_entrada = nomeEmpresa + brutoInfo.seguradora
                  return {
                    nomeEmpresa: {
                      rawValue: nomeEmpresa,
                      displayItem: abrevEmpresa,
                    },
                    dataCargaBrutos: {
                      rawValue: brutoInfo.data_carga,
                      displayItem: new Date(brutoInfo.data_carga).toLocaleDateString()
                    },
                    seguradora: {
                      rawValue: brutoInfo.seguradora,
                    },
                    datasCompetencia: {
                      rawValue: brutoInfo.datas_competencia,
                      displayItem: <Button
                        id={id_entrada}
                        onClick={() => {this.toggle_modal(brutoInfo.datas_competencia)}}
                      >
                        <i className="fa fa-eye" />
                      </Button>
                    }
                  };
                })}
                headers={[
                  {
                    key: "nomeEmpresa",
                    label: "Empresa",
                    sortable: true,
                  },
                  {
                    key: "seguradora",
                    label: "Seguradora",
                    sortable: true,
                  },
                  {
                    key: "dataCargaBrutos",
                    label: "Carga mais recente",
                    sortable: true,
                  },
                  {
                    key: "datasCompetencia",
                    label: "Datas de Competencia",
                    sortable: false,
                  }
                ]}
                defaultSortKey="nomeEmpresa"
                // TODO: Mover busca pra componente de tabela e remover isso
                skipSort={this.state.busca !== ""}
              />
            </Card>
            {modalItem}
          </div>
        </Row>
      </>
    );
  }
}

class DataBrutosAsyncWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      listaBrutos: [],
    };
    // this.empresa = this.props.empresa;
    this.id_empresa = this.props.id_empresa;
    this.seguradora = this.props.seguradora;

    this.updateLista = this.updateLista.bind(this);
    this.updateEmpresa = this.updateEmpresa.bind(this);
    this.updateSeguradora = this.updateSeguradora.bind(this);
    this.updateSubestipulante = this.updateSubestipulante.bind(this);
  }

  componentDidMount() {
    this.updateLista();
  }

  updateLista() {
    /* TODO: Configurar endereços adequadamente */
    hback
      .get("dataCargaBrutos", {
        params: {
          empresa: this.id_empresa,
          seguradora: this.seguradora,
        },
      })
      .then((response) => {
        this.setState({ loaded: true, listaBrutos: response.data });
      })
      .catch((ex) => {
        console.log("dataBrutos: ", ex);
        this.setState({
          loaded: true,
          listaBrutos: [],
        });
      });
  }

  updateEmpresa(empresa_info) {
    this.id_empresa = empresa_info.id_empresa;
    this.updateLista();
    this.props.onChangeEmpresa(empresa_info);
  }

  updateSeguradora(seguradora_info) {
    this.seguradora = seguradora_info.seguradora;
    this.updateLista();
    this.props.onChangeSeguradora(seguradora_info);
  }

  updateSubestipulante(...args) {
    this.props.onChangeSubestipulante(...args);
  }

  render() {
    let componentToRender;
    if (this.state.loaded) {
      componentToRender = (
        <DataBrutos
          listaBrutos={this.state.listaBrutos}
          id_empresa={this.props.empresa}
          seguradora={this.props.seguradora}
        />
      );
    } else {
      componentToRender = <Spinner />;
    }

    return (
      <>
        <Header
          empresa={this.props.empresa}
          listaEmpresas={this.props.listaEmpresas}
          updateEmpresa={this.updateEmpresa}
          seguradora={this.props.seguradora}
          listaSeguradoras={this.props.listaSeguradoras}
          updateSeguradora={this.updateSeguradora}
          subestipulante={this.props.subestipulante}
          listaSubestipulantes={this.props.listaSubestipulantes}
          updateSubestipulante={this.updateSubestipulante}
          loading={this.props.loading}
        />
        <Container className="mt--7" fluid>
          {componentToRender}
        </Container>
      </>
    );
  }
}

export default withProviderInfo(DataBrutosAsyncWrapper);
