/*!

=========================================================
* Healthbot - v2.0.0
* Login Footer
=========================================================

* Copyright 2019 Healthbit

* Coded by Kayne G.I. <kayne.goncalves@healthbit.com.br>

=========================================================

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import logoBranco from "../../assets/img/brand/healthbit-logo-branco.png";
import logo from "../../assets/img/brand/healthbit-logo.png";

const HealthbotNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand>
            <img alt="..." src={logoBranco} />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="http://healthbit.com.br" target="_blank">
                    <img src={logo} />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <a
                className="font-weight-bold ml-1"
                href="https://bi.healthbit.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <NavItem>
                  <Button className="my-4" color="lighter" type="button">
                    Acessar o BI
                  </Button>
                </NavItem>
              </a>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HealthbotNavbar;
