
import React from "react";
import { Spinner } from "reactstrap";

// reactstrap components
import { Container, Row } from "reactstrap";
import HeaderCard from "./HeaderCard/index";

class Header extends React.Component {
    render() {
        let cards_headers = [];
        let erros = {};
        if(this.props.erros){
            erros = this.props.erros;
        }

        if (this.props.loading){
            cards_headers.push(<Spinner
                key="spinner"
            />)
        }
        else{
            if (process.env.REACT_APP_ENABLE_EMPRESA === "true"){
                cards_headers.push(<HeaderCard
                    titulo={"Empresa"}
                    valor={this.props.empresa}
                    listaValores={this.props.listaEmpresas}
                    updateValor={this.props.updateEmpresa}
                    tipo_card={"empresa"}
                    key={"empresa"}
                    erro={erros.empresa}
                />)
            };

            if (process.env.REACT_APP_ENABLE_SEGURADORA === "true") {
                cards_headers.push(<HeaderCard
                    titulo={"Seguradora"}
                    valor={this.props.seguradora}
                    listaValores={this.props.listaSeguradoras}
                    updateValor={this.props.updateSeguradora}
                    tipo_card={"seguradora"}
                    key={"seguradora"}
                    erro={erros.seguradora}
                />)
            };

            if (process.env.REACT_APP_ENABLE_SUBESTIPULANTE === "true") {
                cards_headers.push(<HeaderCard
                    titulo={"Subestipulante"}
                    valor={this.props.subestipulante}
                    listaValores={this.props.listaSubestipulantes}
                    updateValor={this.props.updateSubestipulante}
                    tipo_card={"subestipulante"}
                    key={"subestipulante"}
                />)
            };
        }

        return (
        <>
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            {cards_headers}
                        </Row>
                    </div>
                </Container>
            </div>
        </>
        );
    }
}

export default Header;
