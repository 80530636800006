import React from "react";
import StyledCheckbox from '../Checkboxes/Checkbox';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Alert,
} from "reactstrap";

let mensagens_de_error = {
  empty: "Campo obrigatório",
  nome_em_uso: "Nome já utilizado!",
  invalid_character: "Nome não pode conter /!"
}


class FormCardRelatorio extends React.Component {
  constructor(props){
    super(props);

    this.handleNomeChange = this.handleNomeChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.saveRelatorio = this.saveRelatorio.bind(this);
  }

  handleNomeChange(e){
    this.props.updateFunctions["nome"](e.target.value);
  }

  handleDescChange(e){
    this.props.updateFunctions["descricao"](e.target.value);
  }

  saveRelatorio(e){
    e.preventDefault();
    if(!this.props.nosave){
      this.props.onSave();
    }
  }

  render() {
    let erro = "";
    if (this.props.erros.nome){
      erro = <Alert color="danger">{mensagens_de_error[this.props.erros.nome]}</Alert>
    }
    return (
      <>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h2 className="mb-0">{this.props.titulo}</h2>
              </Col>
              <Col className="text-right" xs="4">
                <Button
                  color="primary"
                  href="/relatorios"
                  size="sm"
                >
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.saveRelatorio}>
              <h6 className="heading-small text-muted mb-4">
                Informações do Relatório
                  </h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                        >
                        Nome do Relatório
                      </label>
                      {erro}
                      <Input
                        className="form-control-alternative"
                        id="input-nomerelatorio"
                        placeholder="2 - Vard Electro - Amil"
                        type="text"
                        defaultValue={this.props.nome}
                        onChange={this.handleNomeChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Autor do Relatório:
                    </label>
                    <p> {this.props.autor} </p>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
              {/* Descrição */}
              <div className="pl-lg-4">
                <FormGroup>
                  <label>Descrição</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Informaçãoes pertinentes sobre o relatório..."
                    rows="7"
                    type="textarea"
                    defaultValue={this.props.descricao}
                    onChange={this.handleDescChange}
                  />
                </FormGroup>
              </div>
            </Form>
            { !this.props.nosave &&
              <Row className="d-flex justify-content-between" >
                <Col xs="6" className="pl-5">
                  <StyledCheckbox
                    id="seguir"
                    label="Seguir Relatório"
                    checked={this.props.seguir}
                    onChange={this.props.onChangeSeguir}
                    strong
                  />
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    color="primary"
                    onClick={this.props.onSave}
                    size="sm"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            }
          </CardBody>
        </Card>
      </>
    );
  }
}

export default FormCardRelatorio;
