import React from "react";
import hback from "../../utils/httpService";

// TODO: Nome decente pra isso
// TODO: Ver se dá pra arrumar a implementação pra ser menos copy/paste?
// TODO: Gerenciar melhor o loading se várias requests simltâneas estiverem causando problemas?
function withProviderInfo(
  WrappedComponent,
  empresa = null,
  db_empresa = null,
  seguradora = null,
  subestipulante = null
) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      let initialState = {
        empresa: empresa,
        db_empresa: db_empresa,
        seguradora: seguradora,
        subestipulante: subestipulante,
        listaEmpresas: [],
        listaSeguradoras: [],
        listaSubestipulantes: [],
      };

      // Guardando aqui além do state porque o timing do state não permite que algumas das
      // requisições ocorram corretamente
      // Também guardando no state para a informação propagar para componentes descendentes
      this.empresa = empresa;
      // Gambiarra terrível por favor arrume
      if (db_empresa) {
        let splitted = db_empresa.split("_");
        this.id_empresa = splitted[splitted.length - 1];
        initialState.id_empresa = this.id_empresa;
      } else {
        this.id_empresa = null;
      }
      this.seguradora = seguradora;
      this.subestipulante = subestipulante;

      this.state = initialState;

      this.updateEmpresas = this.updateEmpresas.bind(this);
      this.updateSeguradoras = this.updateSeguradoras.bind(this);
      this.updateSubestipulantes = this.updateSubestipulantes.bind(this);
      this.selectEmpresa = this.selectEmpresa.bind(this);
      this.selectSeguradora = this.selectSeguradora.bind(this);
      this.selectSubestipulante = this.selectSubestipulante.bind(this);
    }

    updateEmpresas() {
      if (process.env.REACT_APP_ENABLE_EMPRESA === "true") {
        this.setState({ loading: true });
        hback
          .get("listaEmpresas", {
            params: {
              seguradora: this.seguradora,
            },
          })
          .then((response) => {
            const raw_lista = response.data;
            let listaEmpresas = [
              {
                nome: "Tudo",
                db_empresa: null,
                empresa: null,
              },
            ];

            raw_lista.forEach((raw_empresa) => {
              listaEmpresas.push({
                nome: raw_empresa.nome,
                empresa: raw_empresa.nome,
                db_empresa: raw_empresa.database,
                id_empresa: raw_empresa.id,
              });
            });

            this.setState({
              listaEmpresas: listaEmpresas,
              loading: false,
            });
          })
          .catch(() => {
            // TODO: tratar erros
            this.setState({ loading: false });
          });
      }
    }

    updateSeguradoras() {
      if (process.env.REACT_APP_ENABLE_SEGURADORA === "true") {
        this.setState({ loading: true });
        hback
          .get("listaSeguradoras", {
            params: {
              empresa: this.id_empresa,
            },
          })
          .then((response) => {
            const raw_lista = response.data;
            let listaSeguradoras = [
              {
                nome: "Tudo",
                seguradora: null,
              },
            ];

            raw_lista.forEach((seguradora) => {
              listaSeguradoras.push({
                nome: seguradora,
                seguradora: seguradora,
              });
            });

            this.setState({
              listaSeguradoras: listaSeguradoras,
              loading: false,
            });
          })
          .catch(() => {
            // TODO: tratar erros
            this.setState({ loading: false });
          });
      }
    }

    // TODO: Ver como ficam as paradas de subestipulantes
    updateSubestipulantes() {
      if (process.env.REACT_APP_ENABLE_SUBESTIPULANTE === "true") {
        this.setState({ loading: true });
        hback
          .get("listaSubs", {
            params: {
              empresa: this.id_empresa,
              seguradora: this.seguradora,
            },
          })
          .then((response) => {
            const raw_lista = response.data;
            let listaSubs = [
              {
                nome: "Tudo",
                subestipulante: null,
              },
            ];

            raw_lista.forEach((subestipulante) => {
              listaSubs.push({
                nome: subestipulante,
                subestipulante: subestipulante,
              });
            });
            this.setState({
              listaSubestipulantes: listaSubs,
              loading: false,
            });
          })
          .catch(() => {
            // TODO: tratar erros
            this.setState({ loading: false });
          });
      }
    }

    selectEmpresa(empresa_info) {
      this.empresa = empresa_info.empresa;
      this.id_empresa = empresa_info.id_empresa;
      this.setState({
        empresa: empresa_info.empresa,
        db_empresa: empresa_info.db_empresa,
        id_empresa: empresa_info.id_empresa,
      });

      this.updateSeguradoras();
      this.updateSubestipulantes();
    }

    selectSeguradora(seguradora_info) {
      this.seguradora = seguradora_info.seguradora;
      this.setState({
        seguradora: seguradora_info.seguradora,
      });

      this.updateEmpresas();
      this.updateSubestipulantes();
    }

    selectSubestipulante(subestipulante_info) {
      this.subestipulante = subestipulante_info.subestipulante;
      this.setState({
        subestipulante: subestipulante_info.subestipulante,
      });
    }

    componentDidMount() {
      if (process.env.REACT_APP_ENABLE_EMPRESA === "false") {
        this.setState({
          empresa: process.env.REACT_APP_EMPRESA,
          db_empresa: process.env.REACT_APP_DB_EMPRESA,
        });
      }
      if (process.env.REACT_APP_ENABLE_SEGURADORA === "false") {
        this.setState({ seguradora: process.env.REACT_APP_SEGURADORA });
      }
      if (
        process.env.REACT_APP_ENABLE_SUBESTIPULANTE === "false" &&
        process.env.REACT_APP_SUBESTIPULANTE !== ""
      ) {
        this.setState({ subestipulante: process.env.REACT_APP_SUBESTIPULANTE });
      }

      this.updateEmpresas();
      this.updateSeguradoras();
      this.updateSubestipulantes();
    }

    render() {
      return (
        <WrappedComponent
          empresa={this.state.empresa}
          id_empresa={this.state.id_empresa}
          db_empresa={this.state.db_empresa}
          seguradora={this.state.seguradora}
          subestipulante={this.state.subestipulante}
          listaEmpresas={this.state.listaEmpresas}
          listaSeguradoras={this.state.listaSeguradoras}
          listaSubestipulantes={this.state.listaSubestipulantes}
          onChangeEmpresa={this.selectEmpresa}
          onChangeSeguradora={this.selectSeguradora}
          onChangeSubestipulante={this.selectSubestipulante}
          loading={this.state.loading}
          {...this.props}
        />
      );
    }
  };
}

export default withProviderInfo;
