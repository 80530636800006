import React, { useState } from "react";
import Fuse from "fuse.js";
import PropTypes from "prop-types";

import {
    CardBody,
    CardTitle,
    Row,
    Col,
    Alert,
} from "reactstrap";

import { CardS } from './styles';

const icones_cards = {
    "empresa": <Col className="col-auto">
        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
            <i className="ni ni-building" />
        </div>
    </Col>,
    "seguradora": <Col className="col-auto">
        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
            <i className="ni ni-briefcase-24" />
        </div>
    </Col>,
    "subestipulante": <Col className="col-auto">
        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
            <i className="ni ni-briefcase-24" />
        </div>
    </Col>,
}

const mensagens_de_erro = {
    "empty": "Campo obrigatório"
};

const fuseOptions = {
    keys: ["nome"],
    threshold: 0.4,
}

const montaItensDropdown = (valores, busca, callback) => {
    let listaValores;
    if (busca.length > 0) {
        const fuse = new Fuse(valores, fuseOptions);
        listaValores = fuse.search(busca).map((itemResultado) => { return itemResultado.item });
    }
    else {
        listaValores = valores;
    }

    return listaValores.map((item_info) => {
        return(
            <div
                style={{ cursor: "pointer" }}
                onClick={ () => {callback(item_info)} }
                key={item_info.nome}
            >
                <Row>
                    {item_info.nome}
                </Row>
            </div>
        );
    });
}

function HeaderCard({ valor, listaValores, tipo_card, updateValor, erro, titulo }){
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [busca, setBusca] = useState("");

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const icone = icones_cards[tipo_card];
    const valorExibido = valor || "Tudo";

    let lower_card = <Row
        hidden={!dropdownOpen}
    >
        <input
            type="text"
            name="busca"
            placeholder="Buscar..."
            onChange={(e) => {
                setBusca(e.target.value);
            }}
        />
    </Row>;
    let itens_dropdown;

    if(dropdownOpen){
        itens_dropdown = montaItensDropdown(listaValores, busca, (item_info) => { updateValor(item_info); toggle();})
    }

    let mensagem_erro;
    if (erro){
        mensagem_erro = <Alert color="danger">
            {mensagens_de_erro[erro]}
        </Alert>
    }

    return <>
        <Col lg="6" xl="4">
            <CardS className="card-stats mb-4 mb-xl-0">
                <CardBody>
                    {mensagem_erro}
                    <div
                        style={{cursor:"pointer"}}
                        onClick={toggle}
                    >
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                    >
                                    {titulo}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0 text-uppercase">
                                    {valorExibido}
                                </span>
                            </div>
                            {icone}
                        </Row>
                    </div>
                    {lower_card}
                    {itens_dropdown}
                </CardBody>
            </CardS>
        </Col>
    </>
}

HeaderCard.propTypes = {
    valor: PropTypes.string,
    listaValores: PropTypes.array.isRequired,
    tipo_card: PropTypes.string.isRequired,
    updateValor: PropTypes.func.isRequired,
    erro: PropTypes.string,
    titulo: PropTypes.string.isRequired,
}

export default HeaderCard;
