import React from "react";
import PropTypes from "prop-types";


const StyledCheckbox = ({ id, checked, label, onChange, strong, indeterminate }) => {
    let labelComponent;
    const checkboxRef = React.useRef();

    if(strong){
        labelComponent = <h6 className="heading-small mb-4">
            {label}
        </h6>
    }
    else {
        labelComponent = label;
    }

    React.useEffect(() => {
        checkboxRef.current.indeterminate = indeterminate;
    }, [indeterminate]);

    return (
        <>
            <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                    ref={checkboxRef}
                    className="custom-control-input"
                    type="checkbox"
                    onChange={(e)=>{
                        onChange(e.target.checked);
                    }}
                    id={id}
                    checked={checked}
                />
                <label
                    className="custom-control-label"
                    htmlFor={id}
                >
                    {labelComponent}
                </label>
            </div>
        </>
    )
};

StyledCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    strong: PropTypes.bool,
    indeterminate: PropTypes.bool,
};

StyledCheckbox.defaultProps = {
    checked: false,
    label: "",
    strong: false,
    indeterminate: false,
};

export default StyledCheckbox;