import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
} from "reactstrap";

import PaginatedTableHeader from "./PaginatedTableHeader";
import PaginatedTableBody from "./PaginatedTableBody";

const itemsPerPage = 10;

// TODO: limitar quantos botões de paginação mostra em baixo?
let botoes_vizinhos_visiveis = 3


const criaBotoesPaginas = (page, setPage, numItems) => {
    const numPages = Math.ceil(numItems / itemsPerPage);
    let botoesPaginas = [];
    let skipped = false;
    for(let i = 0; i < numPages; i++){
        if( i < 3 || (numPages - i) < 3 ||  Math.abs(i - page) < botoes_vizinhos_visiveis){
            skipped = false;
            botoesPaginas.push(
                <PaginationItem key={'botao' + i}>
                    <PaginationLink
                        href="#"
                        onClick={()=> {setPage(i + 1)}}
                    >
                        {i + 1} <span className="sr-only">{i + 1 === page?"(current)":""}</span>
                    </PaginationLink>
                </PaginationItem>
            );
        }
        else if(!skipped){
            skipped = true;
            botoesPaginas.push(
                <PaginationItem key={'skip' + i}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            )
        }
    }

    return botoesPaginas;
}

const sortItems = (items, sortKey, reverseSort) => {
    if(sortKey){
        const sortedItems = items.sort((item1, item2) => {
            if(item1[sortKey].rawValue < item2[sortKey].rawValue){
                return reverseSort ? 1 : -1;
            }
            else if(item1[sortKey].rawValue > item2[sortKey].rawValue){
                return reverseSort ? -1 : 1;
            }
            return 0;
        });
        return sortedItems;
    }
    return items;
}


const PaginatedTable = ({ items, headers, defaultSortKey, startRev, skipSort }) => {
    const [page, setPage] = useState(1);
    const [sortKey, setSortKey] = useState(defaultSortKey);
    const [reverseSort, setReverseSort] = useState(startRev);

    let sortedItems;
    // TODO: Mover busca pra componente de tabela e remover isso
    if(skipSort){
        sortedItems = items;
    }
    else{
        sortedItems = sortItems(items, sortKey, reverseSort);
    }

    const botoesPaginas = criaBotoesPaginas(page, setPage, items.length);

    return (
        <>
            <Table className="align-items-center table-flush" responsive>
                <PaginatedTableHeader
                    headers={headers}
                    onSelectSort = {(key, rev) => { setSortKey(key); setReverseSort(rev); setPage(1); }}
                />
                <PaginatedTableBody
                    items={sortedItems.slice(itemsPerPage * (page - 1), itemsPerPage * page)}
                    headers={headers}
                />
            </Table>
            <Pagination>
                <PaginationItem className={page === 1?'disabled': ''}>
                    <PaginationLink
                        href="#"
                        onClick={() => {setPage(page - 1);}}
                        tabIndex="-1"
                    >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {botoesPaginas}
                <PaginationItem className={page * itemsPerPage >= items.length?"disabled":""}>
                    <PaginationLink
                        href="#p"
                        onClick={() => {setPage(page + 1);}}
                    >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </>
    );
};

PaginatedTable.propTypes = {
    items: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    defaultSortKey: PropTypes.string,
    startRev: PropTypes.bool,
    skipSort: PropTypes.bool,
};

PaginatedTable.defaultProps = {
    startRev: false,
    skipSort: false,
}

export default PaginatedTable;