import React from "react";
import { useState } from "react";
import {
    Button,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {
    Div,
} from './styles';

import { Link } from "react-router-dom";
import hback from "../../../utils/httpService";

function Actions(props){
    const [deleteModalToggle, setDeleteModalToggle] = useState(false);
    const toggleDeleteModal = () => setDeleteModalToggle(!deleteModalToggle);
    const [dropdownActive, setDropdownActive] = useState(false);
    let downloadItem;
    if (props.downloadLink !== undefined){
        downloadItem = <DropdownItem
            href={props.downloadLink}
            id={props.id_elemento + "-baixar"}
        >
            <i className="ni ni-cloud-download-95" />
            Baixar
        </DropdownItem>
    }

    const apagarRelatorio = () => {
        hback.delete("relatorio/" + props.id_elemento, {})
            .then(() => {
                window.location.reload();
            })
    };

    const modalApagarRelatorio = <Modal isOpen={deleteModalToggle} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
            Deletar Relatório
        </ModalHeader>
        <ModalBody>
            Deseja realmente apagar este relatório?
        </ModalBody>
        <ModalFooter>
            <Button onClick={apagarRelatorio}>Sim</Button>
            <Button onClick={toggleDeleteModal}>Não</Button>
        </ModalFooter>
    </Modal>

    let apagarItem;
    if (props.can_delete){
        apagarItem = <DropdownItem
        href="#"
        id={props.id_elemento + "-apagar"}
        onClick={toggleDeleteModal}
        >
            <i className="ni ni-fat-remove" />
            Apagar
        </DropdownItem>
    }

    let editarItem;
    if(props.can_edit){
        editarItem = <Link to={"/editarrelatorio/" + props.id_elemento}>
            <DropdownItem
                id={props.id_elemento + "-editar"}
            >
                <i className="ni ni-ruler-pencil" />
                Editar
            </DropdownItem>
        </Link>
    }

    let clonar = () => {
        hback.post("/relatorio/" + props.id_elemento, { clone:true })
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                // TODO: Tratar erro (Colocar algum aviso na tela?)
            });
    };

    return (
        <>
            {modalApagarRelatorio}
            {/* <UncontrolledDropdownS> */}
            {/* <Wrapper> */}
                <Button
                    className="btn-icon-only"
                    href="#pablo"
                    role="button"
                    color=""
                    onClick={e => {e.preventDefault(); setDropdownActive(!dropdownActive)}}
                >
                <i className="fas fa-ellipsis-v" />
                </Button>
                    {dropdownActive && <Div
                        // role="menu"
                    >
                        {/* <DropdownMenu className="dropdown-menu-arrow" right> */}
                            {/* Editar */}
                            {editarItem}
                            {/* Baixar */}
                            {downloadItem}
                            {/* Apagar */}
                            {apagarItem}
                            {/* Copiar */}
                            <DropdownItem
                                href="#"
                                id={props.id_elemento + "-copiar"}
                                onClick={clonar}
                            >
                                <i className="ni ni-single-copy-04" />
                                Copiar
                            </DropdownItem>
                            {/* Enviar dados já processados */}
                            <DropdownItem
                                href="#"
                                id={props.id_elemento + "-enviar"}
                                onClick={() => {
                                    hback.post("executaRelatorio/" + props.id_elemento,
                                    {
                                        export_only: false,
                                    })
                                    .then(() => {
                                        window.location.reload();
                                    })
                                }}
                            >
                                <i className="ni ni-curved-next" />
                                Processar e exportar
                            </DropdownItem>
                            <DropdownItem
                                href="#"
                                id={props.id_elemento + "-enviar-processados"}
                                onClick={() => {
                                    hback.post("executaRelatorio/" + props.id_elemento,
                                        {
                                            export_only: true,
                                        })
                                        .then(() => {
                                            window.location.reload();
                                        })
                                }}

                            >
                                <i className="ni ni-send" />
                                Exportar sem reprocessar
                            </DropdownItem>
                        {/* </DropdownMenu> */}
                    </Div>}
                {/* </Wrapper> */}
            {/* </UncontrolledDropdownS> */}
        </>
        );
    }

export default Actions;
