import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";

import hback from "../../utils/httpService";
import FormRelatorio from "../../components/Forms/FormRelatorio.jsx";
import withProviderInfo from "../../components/Wrappers/ProviderWrapper";

function EditarRelatorio() {
  let { id } = useParams();
  const [relatorio, setRelatorio] = useState(null);
  let componentToRender;
  if (relatorio) {
    const FormRelatorioWithProviderInfo = withProviderInfo(
      FormRelatorio,
      relatorio.empresa,
      relatorio.database,
      relatorio.seguradora,
      relatorio.subestipulante
    );
    componentToRender = (
      <FormRelatorioWithProviderInfo
        relatorio={relatorio}
        titulo="Editar Relatório"
        method="put"
        id={relatorio.id}
      />
    );
  } else {
    componentToRender = <Spinner color="primary" />;
    hback.get("relatorio/" + id, {}).then((response) => {
      setRelatorio(response.data);
    });
  }
  return <>{componentToRender}</>;
}

export default EditarRelatorio;
