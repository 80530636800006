import {
    Card,
    CardHeader,
    Row,
    Col,
    CardBody,
  } from "reactstrap";
  import Divider from '@mui/material/Divider';

  const ModalCargaBrutos = (props) => {
    const items = props.infoBrutos.map(
        (item) => {
            return <Row>
                <Col>
                    {(""+item.data_competencia.month).padStart(2, '0') + '/' + item.data_competencia.year}
                </Col>
                <Col>
                    {new Date(item.data_carga).toLocaleDateString()}
                </Col>
            </Row>
        }
    )
    return <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
            <h2 className="mb-0">Datas de carga dos dados brutos</h2>
        </CardHeader>
        <CardBody>
            <Row>
                <Col>
                    Data Competencia
                </Col>
                <Col>
                    Data de carga
                </Col>
            </Row>
            <Divider />
            {items}
        </CardBody>
    </Card>
  }

  export default ModalCargaBrutos;