import React, { useState } from "react";

import {
  Input,
  Row,
  Col,
  FormGroup,
  FormFeedback,
} from "reactstrap";

function convertDate(date) {
  return date.toISOString().substring(0, 10);
};

function dateToDayOne(date){
  date.setUTCDate(1);
  return date;
};

function Periodo({ value, updateFunction }) {
  value = value || {};

  let [erroMovel, setErroMovel] = useState(false);

  if (typeof (value) === "string"){
    let orig_value = value;
    value = { tipo: "movel" };
    if(orig_value === "ano") {
      value.movel = 12;
    }
    else if (orig_value === "mes"){
      value.movel = 1;
    }
  }

  if (typeof (value.fixo_de) === "string") {
    value.fixo_de = new Date(value.fixo_de);
  }

  if (typeof (value.fixo_ate) === "string") {
    value.fixo_ate = new Date(value.fixo_ate);
  }

  return (
    <>
      <div className="custom-control custom-control-alternative">
        <Row>
          <Col>
            <FormGroup>
              <span className="text-muted">Período Referência</span>
              <Row>
                <Col style={{ marginLeft: "24px" }}>
                  <Input type="radio" name="tipo_periodo" id="tipo_periodo_fixo"
                    value="fixo"
                    label="Intervalo Fixo"
                    checked={value.tipo === "fixo"}
                    onChange={() => {
                      let newValue = value;
                      newValue.tipo = "fixo";
                      newValue.movel = null;
                      updateFunction(newValue);
                    }}
                  />
                  <span className="text-muted">Intervalo fixo</span>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Input type="date" id="filtro_simples_de"
                    value={value.fixo_de ? convertDate(value.fixo_de) : ""}
                    onChange={(e) => {
                      let newValue = value;
                      newValue.tipo = "fixo";
                      newValue.movel = null;
                      newValue.fixo_de = dateToDayOne(e.target.valueAsDate);
                      updateFunction(newValue);
                    }}
                  />
                </Col>
                <Col lg="6">
                  <Input type="date" id="filtro_simples_ate"
                    value={value.fixo_ate ? convertDate(value.fixo_ate) : ""}
                    onChange={(e) => {
                      let newValue = value;
                      newValue.tipo = "fixo";
                      newValue.movel = null;
                      newValue.fixo_ate = dateToDayOne(e.target.valueAsDate);
                      updateFunction(newValue);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ marginLeft: "24px" }}>
                  <Input type="radio" name="tipo_periodo" id="tipo_periodo_movel"
                    checked={value.tipo === "movel"}
                    onChange={() => {
                      let newValue = value;
                      newValue.tipo = "movel";
                      newValue.fixo_de = null;
                      newValue.fixo_ate = null;
                      updateFunction(newValue);
                    }}

                  />
                  <span className="text-muted">Intervalo móvel
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      Últimos
                      <Input type="number" style={{ width: "80px", margin: "12px" }}
                        value={value.movel || ""}
                        min="0"
                        invalid={erroMovel}
                        onChange={(e) => {
                          let valor_input = e.target.value;
                          if(valor_input >= 0){
                            let newValue = value;
                            newValue.tipo = "movel";
                            newValue.fixo_de = null;
                            newValue.fixo_ate = null;
                            newValue.movel = e.target.value;
                            setErroMovel(false);
                            updateFunction(newValue);
                          }
                          else{
                            setErroMovel(true);
                          }
                        }}
                      />
                      <FormFeedback tooltip>
                        Valor não pode ser negativo!
                      </FormFeedback>
                      meses
                    </div>
                  </span>
                </Col>
              </Row>
              <Row>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Periodo;
