import React from "react";

import { Input, Row, Col, FormGroup } from "reactstrap";

const Idade = (props) => {

  function handleChangeDe(e) {
    let value = props.value;
    value.de = e.target.value;
    props.updateFunction(value);
  }

  function handleChangeAte(e) {
    let value = props.value;
    value.ate = e.target.value;
    props.updateFunction(value);
  }

  return (
    <>
      <div className="custom-control custom-control-alternative">
        <Row>
          <Col>
            <FormGroup>
              <span className="text-muted">Idade</span>
              <Row>
                <Col lg="6">
                  <Input
                    className="form-control-alternative"
                    id="idadeDe"
                    placeholder="De"
                    type="number"
                    defaultValue={props.value.de}
                    onChange={handleChangeDe}
                  />
                </Col>
                <Col lg="6">
                  <Input
                    className="form-control-alternative"
                    id="idadeAte"
                    placeholder="Até"
                    type="number"
                    defaultValue={props.value.ate}
                    onChange={handleChangeAte}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Idade;
