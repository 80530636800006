import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="copyright text-center text-muted">
        Copyright © {new Date().getFullYear() + " "}
        <a
          className="font-weight-bold ml-1"
          href="https://www.healthbit.com.br"
          rel="noopener noreferrer"
          target="_blank"
        >
          Healthbit
        </a>
      </div>
    </footer>
  );
};

export default Footer;
