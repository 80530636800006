import React from "react";

import {
    Input,
    Row,
    Col,
    FormGroup,
} from "reactstrap";

class Valores extends React.Component {
  constructor(props){
    super(props);

    this.handleChangeDe = this.handleChangeDe.bind(this);
    this.handleChangeAte = this.handleChangeAte.bind(this);
  }

  handleChangeDe(e) {
    let value = this.props.value;
    value.de = e.target.value;
    this.props.updateFunction(value);
  }

  handleChangeAte(e) {
    let value = this.props.value;
    value.ate = e.target.value;
    this.props.updateFunction(value);
  }

  render() {
    return (
      <>
        <div className="custom-control custom-control-alternative">
          <Row>
            <Col>
              <FormGroup>
                <span className="text-muted">Valores</span>
                <Row>
                  <Col lg="6">
                    <Input
                      className="form-control-alternative"
                      id="valoresDe"
                      placeholder="De"
                      type="number"
                      defaultValue={this.props.value.de}
                      onChange={this.handleChangeDe}
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      className="form-control-alternative"
                      id="valoresAte"
                      placeholder="Até"
                      type="number"
                      defaultValue={this.props.value.ate}
                      onChange={this.handleChangeAte}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Valores;