import React from "react";
import { useState } from "react";

import {
  Button,
} from "reactstrap";

import FormFiltroComplexo from "../../Forms/FormFiltroComplexo/index"


const ModalFiltroComplexo = ({
  filtro,
  onSave,
  close,
  analises,
  ...props}) => {
  const nomeFiltroAntigo = filtro.nome;
  const [nome, setNome] = useState(filtro.nome);
  const [nomeArquivo, setNomeArquivo] = useState(filtro.nome_arquivo);
  const [arquivo, setArquivo] = useState(null);
  const [origem, setOrigem] = useState(filtro.origem || "Filtro Simples");
  const [exportType, setExportType] = useState(filtro.export_filter || "resumo_filtro");
  const [analisesSelecionadas, setAnalisesSelecionadas] = useState(filtro.analises || {});
  const [exportarBrutos, setExportarBrutos] = useState(filtro.exportar_brutos || false);
  const [exportarBrutosAnalises, setExportarBrutosAnalises] = useState(filtro.exportar_brutos_analises || false);

  const save = () => {
    const filtroUpdateObject = {
      nome:nome,
      nome_arquivo: nomeArquivo,
      arquivo: arquivo,
      origem: origem,
      export_filter: exportType,
      analises: analisesSelecionadas,
      exportar_brutos: exportarBrutos,
      exportar_brutos_analises: exportarBrutosAnalises,
    }
    if (onSave(nome, nomeFiltroAntigo, filtroUpdateObject)){
      close();
    };
  }

  // TODO: Verificar limitações de lidar com arquivos no js e axios
  const updateArquivo = (arquivo) => {
    setArquivo(arquivo);
    setNomeArquivo(arquivo.name);
  }

  const updateAnalises = (value) => {
    // O React não detecta mudança se o conteúdo do objeto muda por algum motivo...
    let gambCopy = {};
    Object.entries(value).forEach(([k, v]) => {
      gambCopy[k] = v;
    });
    setAnalisesSelecionadas(gambCopy);
  }

  const updateExportarBrutos = (checked) => {
    setExportarBrutos(checked);
  }

  const updateExportarBrutosAnalises = (checked) => {
    setExportarBrutosAnalises(checked);
  }

  return (
    <>
      <FormFiltroComplexo
        nome={nome}
        nome_arquivo={nomeArquivo}
        origem={origem}
        update_nome={setNome}
        update_origem={setOrigem}
        update_arquivo={updateArquivo}
        onUpdateAnalises={updateAnalises}
        analisesSelecionadas={analisesSelecionadas}
        analises={analises}
        nomeAntigo={nomeFiltroAntigo}
        exportarBrutos={exportarBrutos}
        updateExportarBrutos={updateExportarBrutos}
        exportarBrutosAnalises={exportarBrutosAnalises}
        updateExportarBrutosAnalises={updateExportarBrutosAnalises}
        close={close}
        exportType={exportType}
        conteudo={filtro.conteudo}
        onChangeExportType={setExportType}
        {...props}
      />
      <div className="modal-footer">
        <Button
          color="default"
          type="button"
          onClick={save}
        >
          Salvar
        </Button>

      </div>
    </>
  );
}

export default ModalFiltroComplexo;
