import * as jose from "jose";
import httpService from "./httpService";
export const tokenKey = "token";

httpService.setJwt(getJwt());

export async function login(loginInfo) {
  const { data: jwt } = await httpService.post("login", loginInfo);
  const expireDate = getDate(loginInfo.lembrar);
  localStorage.setItem(tokenKey, jwt);
  document.cookie = "auth=" + jwt + ";expires=" + expireDate + ";path=/";
}

function getDate(lembrar) {
  let now = new Date();
  let now_milliseconds = Date.UTC(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  );
  let future_milliseconds;
  if (lembrar) {
    future_milliseconds = 1000 * 3600 * 24 * 30;
  } else {
    future_milliseconds = 1000 * 3600 * 8;
  }

  return new Date(now_milliseconds + future_milliseconds);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  // mata o cookie setando uma data do passado
  document.cookie = "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCurentuser() {
  try {
    const jwtToken = localStorage.getItem(tokenKey);
    return jose.decodeJwt(jwtToken);
  } catch (ex) {
    console.log("autentication error:", ex);
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

// Roubado diretamente de https://www.w3schools.com/js/js_cookies.asp
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const auth = {
  login,
  logout,
  getCurentuser,
  getJwt,
  getCookie,
};

export default auth;
