import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
} from "reactstrap";

import AuthNavbar from "../../../components/Navbars/AuthNavbar.jsx";
import logo from "../../../assets/img/brand/healthbit-logo.png";
import Background from "./Background";
import auth from "../../../utils/hbackAuth.jsx";

function Login() {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [lembrar, setLembrar] = useState(false);
  const [erro, setErro] = useState(false);
  let location = useLocation();

  function onUsuarioChange(e) {
    setUsuario(e.target.value);
  }

  function onSenhaChange(e) {
    setSenha(e.target.value);
  }

  function onLembrarChange(e) {
    setLembrar(e.target.value);
  }

  async function onSubmit() {
    try {
      await auth.login({ usuario, senha, lembrar, erro });
      // redireciona o usuario para a url que pretendia ir antes de ser obrigado a logar
      const { state } = location;
      window.location = state ? state.from.pathname : "/relatorios";
    } catch (ex) {
      console.log(ex);
      // TODO dar uma melhorada nisso
      setErro(true);
      // if (ex.response && ex.response.status === 400) {
      // }
    }
  }

  return (
    <div className="main-content">
      <AuthNavbar />
      <Background />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="btn-wrapper text-center">
                  <img alt="..." src={logo} />
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Usuário"
                        value={usuario}
                        onChange={(e) => onUsuarioChange(e)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Senha"
                        type="password"
                        value={senha}
                        onChange={(e) => onSenhaChange(e)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {erro && (
                    <Alert color="danger">Email ou senha inválidos.</Alert>
                  )}
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                      checked={lembrar}
                      onChange={(e) => onLembrarChange(e)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Lembrar</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button
                      color="primary"
                      // TODO: Colocar essa função num lugar adequado
                      onClick={() => onSubmit()}
                    >
                      Entrar
                    </Button>
                  </div>
                  <Input type="submit" hidden />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
